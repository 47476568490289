import React from 'react'

import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    DateInput,
    required,
    useCreateController,
    useEditController,
    TabbedForm,
    FormTab,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { Paper } from '@material-ui/core'
import { formatXmlDate } from '../../functions'

export const HotelRoomPreReservationCreate = (props) => {
    const { record } = useCreateController(props)
    return (
        <Create mutationMode="pessimistic" {...props}>
            <TabbedForm redirect={`/hotelRooms/${record.hotelRoomId}/show`}>
                <FormTab label="Resumo">
                    <DateInput
                        label="Início"
                        source="dateStart"
                        validate={[required()]}
                        inputProps={{
                            min: formatXmlDate(new Date()),
                        }}
                    />
                    <DateInput
                        label="Término"
                        source="dateEnd"
                        validate={[required()]}
                        inputProps={{
                            min: formatXmlDate(new Date()),
                        }}
                    />
                    <TextInput
                        label="Diárias"
                        source="days"
                        validate={[required()]}
                    />
                    <TextInput
                        multiline
                        label="Descrição"
                        source="description"
                    />
                    <Paper style={{ padding: '0 10px' }}>
                        <h3>Acomodação do quarto</h3>
                        <TextInput
                            label="Adultos"
                            source="adt"
                            validate={[required()]}
                        />
                        <TextInput
                            label="Crianças"
                            source="chd"
                            validate={[required()]}
                        />
                        <TextInput
                            label="Idade máxima criança"
                            source="chdMaxAge"
                            validate={[required()]}
                        />
                    </Paper>
                    <BooleanInput
                        label="Exibir na página inicial"
                        source="firstPage"
                    />
                    <BooleanInput
                        label="Permite cupom de desconto"
                        source="allowCoupon"
                    />
                    <BooleanInput label="Ativo" source="active" />
                </FormTab>
                <FormTab label="Itens Inclusos">
                    <RichTextInput
                        source="included"
                        label="Itens Inclusos"
                        validate={required()}
                        addLabel={false}
                        toolbar={[{ list: 'bullet' }]}
                    />
                </FormTab>
                <FormTab label="Condições gerais">
                    <RichTextInput
                        source="conditions"
                        label="Condições gerais"
                        validate={required()}
                        addLabel={false}
                        toolbar={[{ list: 'bullet' }]}
                    />
                </FormTab>
                <FormTab label="Como utilizar">
                    <RichTextInput
                        source="howToUse"
                        label="Como utilizar"
                        validate={required()}
                        addLabel={false}
                        toolbar={[{ list: 'bullet' }]}
                    />
                </FormTab>
            </TabbedForm>
        </Create>
    )
}

export const HotelRoomPreReservationEdit = (props) => {
    const { record } = useEditController(props)

    if (record)
        return (
            <Edit mutationMode="pessimistic" {...props}>
                <TabbedForm redirect={`/hotelRooms/${record.hotelRoomId}/show`}>
                    <FormTab label="Resumo">
                        <DateInput
                            label="Início"
                            source="dateStart"
                            validate={[required()]}
                            inputProps={{
                                min: formatXmlDate(new Date()),
                            }}
                        />
                        <DateInput
                            label="Término"
                            source="dateEnd"
                            validate={[required()]}
                            inputProps={{
                                min: formatXmlDate(new Date()),
                            }}
                        />
                        <TextInput
                            label="Diárias"
                            source="days"
                            validate={[required()]}
                        />
                        <TextInput
                            multiline
                            label="Descrição"
                            source="description"
                        />
                        <Paper style={{ padding: '0 10px' }}>
                            <h3>Acomodação do quarto</h3>
                            <TextInput
                                label="Adultos"
                                source="adt"
                                validate={[required()]}
                            />
                            <TextInput
                                label="Crianças"
                                source="chd"
                                validate={[required()]}
                            />
                            <TextInput
                                label="Idade máxima criança"
                                source="chdMaxAge"
                                validate={[required()]}
                            />
                        </Paper>
                        <BooleanInput
                            label="Exibir na página inicial"
                            source="firstPage"
                        />
                        <BooleanInput
                            label="Permite cupom de desconto"
                            source="allowCoupon"
                        />
                        <BooleanInput label="Ativo" source="active" />
                    </FormTab>
                    <FormTab label="Itens Inclusos">
                        <RichTextInput
                            source="included"
                            label="Itens Inclusos"
                            validate={required()}
                            addLabel={false}
                            toolbar={[{ list: 'bullet' }]}
                        />
                    </FormTab>
                    <FormTab label="Condições gerais">
                        <RichTextInput
                            source="conditions"
                            label="Condições gerais"
                            validate={required()}
                            addLabel={false}
                            toolbar={[{ list: 'bullet' }]}
                        />
                    </FormTab>
                    <FormTab label="Como utilizar">
                        <RichTextInput
                            source="howToUse"
                            label="Como utilizar"
                            validate={required()}
                            addLabel={false}
                            toolbar={[{ list: 'bullet' }]}
                        />
                    </FormTab>
                </TabbedForm>
            </Edit>
        )
    else return null
}
