import React from 'react'
import { useEffect } from 'react'
import { List, Datagrid, TextField as TextFieldRa, SimpleForm, Create, ImageInput, ImageField, Toolbar, EditButton, DeleteButton, SaveButton, useRefresh, useRedirect, required } from 'react-admin'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import IconType from '@material-ui/icons/MyLocation'
import api from "../../Services/Api"
export const destinyHighlightsIcon = IconType

export const destinyHighlightsList = (props) => {
    return (
        <List {...props} exporter={false} title="Destinos em destaque" bulkActionButtons={false} perPage={25}>
            <Datagrid>
                <TextFieldRa source="cityName" label="Cidade" />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    )
}

const validateImage = [required()]
export const DestinyHighlightsCreate = (props) => {
    const [cities, setCities] = React.useState([])
    const [citySelected, setCitySelected] = React.useState(null)
    const [inputSelectedItem, setInputSelectedItem] = React.useState('')
    const refresh = useRefresh();
    const redirect = useRedirect();

    useEffect(() => {
        (async () => {
            try {
                const result = await api.get(`cities`)
                let citiesResult = result.data.filter(a => a.type === 'C' || a.type === 'G')
                citiesResult = citiesResult.map(city => ({ ...city, name: city.name, state: city.state }))
                setCities(citiesResult)
            } catch (error) {
                console.log("Erro ao consultar hoteis", error)
            }
        })()
    }, [])

    const onSuccess = ({ data }) => {
        redirect('/destinyHighlights');
        refresh();
    };

    const SaveActions = props => (
        <Toolbar {...props}>
            <SaveButton onSuccess={onSuccess} submitOnEnter={true} transform={data => ({ ...data, notify: true, cityCode: citySelected.code, cityName: citySelected.name, type: citySelected.type })} />
        </Toolbar>
    )

    return (
        <Create {...props} >
            <SimpleForm toolbar={<SaveActions />}>
                <Autocomplete
                    value={citySelected}
                    onChange={(event, newValue) => {
                        setCitySelected(newValue)
                    }}
                    inputValue={inputSelectedItem}
                    onInputChange={(event, newInputValue) => {
                        setInputSelectedItem(newInputValue)
                    }}
                    getOptionLabel={(option) => option.state ? option.name + ' - ' + option.state : option.name}
                    id="controllable-states-demo"
                    options={cities}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Selecionar Cidade..." variant="outlined" />}
                />
                <ImageInput source="image" label="Imagem" accept="image/*" validate={validateImage}>
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
