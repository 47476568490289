import axios from "axios";

// const api = axios.create({
//   baseURL: "http://186.225.243.60:4000"
// });

const api = axios.create({
  baseURL: process.env.REACT_APP_PROVIDER_ADDRESS
});


export default api;