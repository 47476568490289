import * as React from 'react'
import { useState } from 'react'
import { useRedirect, useNotify, Notification } from 'react-admin'
import {
    Icon,
    Container,
    CssBaseline,
    TextField,
    Button,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import api from '../Services/Api'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    iconRoot: {
        textAlign: 'center',
        width: 300,
        height: 150,
    },
}))

const ForgotPassword = ({ theme }) => {
    const classes = useStyles()
    const [email, setEmail] = useState('')

    const notify = useNotify()
    const redirect = useRedirect()

    const submit = async (e) => {
        e.preventDefault()
        if (email) {
            let result = null
            try {
                if (window.location.href.includes('melhorseguro')) 
                    result = await api.post('/admin/forgotPassword', { email, provider: "melhorseguro" })
                else
                    result = await api.post('/admin/forgotPassword', { email })

                if (result.status === 200 && !result.data.error) {
                    notify('Instruções enviadas para o e-mail.')
                    redirect('/login')
                } else {
                    notify(result.data.error)
                }
            } catch (error) {
                notify(
                    'Erro ao enviar instruções por e-mail, tente novamente mais tarde.'
                )
            }
        }
    }

    let logo = './logo.svg'
    if (window.location.href.includes('melhorseguro')) {
        logo = './melhorseguro.png'
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Icon classes={{ root: classes.iconRoot }}>
                    <img src={logo} alt="logo" />
                </Icon>
                <form className={classes.form} onSubmit={submit} noValidate>
                    <Typography>
                        Enviar instruções para redefinição de senha por email
                    </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Enviar
                    </Button>
                </form>
            </div>
            <Notification />
        </Container>
    )
}

export default ForgotPassword
