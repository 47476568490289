import React from 'react'
import { useEffect } from 'react'
import api from '../../../Services/Api'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    BooleanField,
    EditButton,
    TextInput,
    BooleanInput,
    SimpleForm,
    Edit,
    DeleteButton,
    required,
    Create,
    Filter,
    SearchInput,
    SelectInput
} from 'react-admin'
import Icon from '@material-ui/icons/BeachAccess'

export const CoveragesIcon = Icon

const ProductFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
)

export const CoveragesList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="Produtos"
            bulkActionButtons={false}
            sort={{ field: 'order', order: 'ASC' }}
            filters={<ProductFilter />}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="name" label="Nome" />
                <TextFieldRa source="CoverageGroup.name" label="Grupo" />
                <BooleanField source="showInResults" label="Exibir na pesquisa" />
                <BooleanField source="isDMH" label="DMH" />
                <TextFieldRa source="order" label="Ordenação" />
                <EditButton label="" />
                <DeleteButton label="" />
            </Datagrid>
        </List>
    )
}

export const CoveragesEdit = (props) => {
    const [coverageGroupsOptions, setCoverageGroupsOptions] = React.useState([])
    
    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(`admin/coverageGroups`)
                setCoverageGroupsOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coverageGroups', error)
            }
        })()
    }, [])

    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    label="Nome"
                    source="name"
                    validate={[required()]}
                />
                <TextInput
                    label="Descrição"
                    source="description"
                    multiline
                />
                <SelectInput
                    label="Grupo"
                    source="groupId"
                    choices={coverageGroupsOptions}
                    resettable
                />
                <TextInput
                    label="Ordenação"
                    source="order"
                />
                <BooleanInput
                    source="showInResults"
                    label="Exibir na pesquisa"
                />
                <BooleanInput
                    source="isDMH"
                    label="DMH"
                />
            </SimpleForm>
        </Edit>
    )
}

export const CoveragesCreate = (props) => {
    const [coverageGroupsOptions, setCoverageGroupsOptions] = React.useState([])
    
    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(`admin/coverageGroups`)
                setCoverageGroupsOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coverageGroups', error)
            }
        })()
    }, [])

    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    label="Nome"
                    source="name"
                    validate={[required()]}
                />
                <TextInput
                    label="Descrição"
                    source="description"
                    multiline 
                />
                <SelectInput
                    label="Grupo"
                    source="groupId"
                    choices={coverageGroupsOptions}
                    resettable
                />
                <TextInput
                    label="Ordenação"
                    source="order"
                />
                <BooleanInput
                    source="showInResults"
                    label="Exibir na pesquisa"
                />
                <BooleanInput
                    source="isDMH"
                    label="DMH"
                />
            </SimpleForm>
        </Create>
    )
}
