import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import {
    Create,
    SimpleForm,
    TextInput,
    RadioButtonGroupInput,
    NumberInput,
    required,
    useCreateController,
    FormDataConsumer,
    useEditController,
    Edit,
} from 'react-admin'
import { Grid, Paper, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { formatXmlDate } from '../../functions'

const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            marginTop: 10,
            padding: 10,
        },
        '& h4': {
            marginTop: 0,
        },
    },
    actions: {
        justifyContent: 'start', //botoes de ação na esquerda da tela
        '& button': {
            margin: 5,
        },
        paddingTop: '0px',
    },
})

export const HotelRoomPreReservationPackageCreate = (props) => {
    const { record } = useCreateController(props)
    return (
        <Create {...props}>
            <SimpleForm redirect={`/hotelRooms/${record.hotelRoomId}/show`}>
                <TextInput
                    label="Diárias"
                    source="days"
                    validate={[required()]}
                />
                <NumberInput
                    label="Tarifa Net"
                    source="fare"
                    validate={[required()]}
                />
                <NumberInput
                    label="ISS %"
                    source="iss"
                    defaultValue="0"
                    validate={[required()]}
                />
                <RadioButtonGroupInput
                    label="Tipo de markup"
                    source="markupType"
                    validate={[required()]}
                    helperText={false}
                    choices={[
                        { id: 1, name: 'Valor Fixo' },
                        { id: 2, name: 'Percentual' },
                    ]}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        (formData.markupType === 1 && (
                            <NumberInput
                                label="Markup"
                                source="markup"
                                validate={[required()]}
                            />
                        )) ||
                        (formData.markupType === 2 && (
                            <NumberInput
                                label="Markup %"
                                source="markupPerc"
                                validate={[required()]}
                            />
                        ))
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}

export const HotelRoomPreReservationPackageEdit = (props) => {
    const { record } = useEditController(props)
    console.log('Record: ', record)
    if (record)
        return (
            <Edit {...props}>
                <SimpleForm redirect={`/hotelRooms/${record.hotelRoomId}/show`}>
                    <TextInput
                        label="Diárias"
                        source="days"
                        validate={[required()]}
                    />
                    <NumberInput
                        label="Tarifa Net"
                        source="fare"
                        validate={[required()]}
                    />
                    <NumberInput
                        label="ISS %"
                        source="iss"
                        defaultValue="0"
                        validate={[required()]}
                    />
                    <RadioButtonGroupInput
                        label="Tipo de markup"
                        source="markupType"
                        validate={[required()]}
                        helperText={false}
                        choices={[
                            { id: 1, name: 'Valor Fixo' },
                            { id: 2, name: 'Percentual' },
                        ]}
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            (formData.markupType === 1 && (
                                <NumberInput
                                    label="Markup"
                                    source="markup"
                                    validate={[required()]}
                                />
                            )) ||
                            (formData.markupType === 2 && (
                                <NumberInput
                                    label="Markup %"
                                    source="markupPerc"
                                    validate={[required()]}
                                />
                            ))
                        }
                    </FormDataConsumer>
                </SimpleForm>
            </Edit>
        )
    else return null
}
