import api from "./Services/Api"

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(process.env.REACT_APP_PROVIDER_ADDRESS + '/auth', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth))
                api.defaults.headers.Authorization = `Bearer ${auth.token}`
            })
            .catch(() => {
                throw new Error('Email ou Senha Inválidos')
            })
    },
    checkError: (error) => {
        const status = error.status
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth')
            return Promise.reject()
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve()
    },
    checkAuth: async () => {
        if (localStorage.getItem('auth')) {
            const { token } = JSON.parse(localStorage.getItem('auth'))
            const request = new Request(process.env.REACT_APP_PROVIDER_ADDRESS + '/validtoken', {
                method: 'POST',
                headers: new Headers({ 'authorization': `Bearer ${token}` }),
            })
            return fetch(request)
                .then(async response => {
                    if (response.status < 200 || response.status >= 300) {
                        return Promise.reject()
                    }
                    const newToken = await response.json()
                    localStorage.setItem('auth', JSON.stringify(newToken))
                    return Promise.resolve()
                })
                .catch(() => {
                    return Promise.reject()
                })
        }
        else {
            return Promise.reject()
        }
    },
    logout: () => {
        localStorage.removeItem('auth')
        api.defaults.headers.Authorization = undefined
        return Promise.resolve()
    },
    getIdentity: () => {
        try {
            if (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth'))) {
                const { user, token } = JSON.parse(localStorage.getItem('auth'))
                api.defaults.headers.Authorization = `Bearer ${token}`
                return Promise.resolve({ id: user.id, fullName: user.name, avatar: null })
            }
            return Promise.reject()
        } catch (error) {
            return Promise.reject(error)
        }
    },
    getPermissions: () => {
        if (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).user) {
            const { user } = JSON.parse(localStorage.getItem('auth'))
            return Promise.resolve(user.role)
        }
        else
            return Promise.resolve('guest')
    }
}

export default authProvider
