import * as React from 'react'
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useLogin, useNotify, Notification } from 'react-admin'
import {
    Icon,
    Container,
    Grid,
    CssBaseline,
    TextField,
    Button,
    Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    iconRoot: {
        textAlign: 'center',
        width: 300,
        height: 150,
    },
}))

const MyLoginPage = ({ theme }) => {
    let logo = './logo.svg'
    if (window.location.href.includes('melhorseguro')) {
        logo = './melhorseguro.png'
    }
    const classes = useStyles()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const login = useLogin()
    const notify = useNotify()
    const submit = (e) => {
        e.preventDefault()
        login({ username: email, password }).catch(() =>
            notify('E-mail ou senha inválidos')
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Icon classes={{ root: classes.iconRoot }}>
                    <img src={logo} alt="logo" />
                </Icon>
                <form className={classes.form} onSubmit={submit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Entrar
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/#/forgotpassword" variant="body2">
                                Esqueceu sua senha?
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Notification />
        </Container>
    )
}

export default MyLoginPage
