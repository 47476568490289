import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import api from '../../../Services/Api'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    EditButton,
    TextInput,
    SimpleForm,
    Edit,
    Filter,
    SearchInput,
    NumberInput,
    FormDataConsumer,
    required,
    Create,
    RadioButtonGroupInput,
    SelectInput,
} from 'react-admin'

const currencyBRL = (value) => {
    value = value.replace('.', '')
    return value
}

export const ProductCoveragesEdit = (props) => {
    const [coverageOptions, setCoverageOptions] = React.useState([])

    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(
                    `admin/coverages?_order=ASC&_sort=name`
                )
                setCoverageOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coberturas', error)
            }
        })()
    }, [])

    const redirect = (basePath, id, data) => {
        return `/products/${data.productId}/show/2`
    }

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm redirect={redirect}>
                <SelectInput
                    label="Cobertura"
                    source="coverageId"
                    choices={coverageOptions}
                    resettable
                    validate={[required()]}
                />
                <TextInput
                    label="Descrição Adicional"
                    source="additionalDescription"
                />
                <RadioButtonGroupInput
                    label="Tipo"
                    source="valueType"
                    helperText={false}
                    choices={[
                        { id: 1, name: 'Valor' },
                        { id: 2, name: 'Sim' },
                        { id: 3, name: 'Não' },
                        { id: 4, name: 'Descrição' },
                    ]}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.valueType === 1 && (
                            <TextInput label="Valor" source="value" {...rest} />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.valueType === 4 && (
                            <TextInput
                                label="Descrição"
                                source="valueDescription"
                                {...rest}
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.valueType === 1 && (
                            <TextInput
                                label="Moeda"
                                source="currency"
                                validate={[required()]}
                            />
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    )
}

export const ProductCoveragesCreate = (props) => {
    const [coverageOptions, setCoverageOptions] = React.useState([])

    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(
                    `admin/coverages?_order=ASC&_sort=name`
                )
                setCoverageOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar coberturas', error)
            }
        })()
    }, [])

    const location = useLocation()
    const redirect = (basePath, id, data) =>
        `/products/${data.productId}/show/2`
    const transform = (data) => ({
        ...data,
        productId: location.state.productId,
    })

    return (
        <Create {...props} mutationMode="pessimistic" transform={transform}>
            <SimpleForm redirect={redirect}>
                <SelectInput
                    label="Cobertura"
                    source="coverageId"
                    choices={coverageOptions}
                    resettable
                    validate={[required()]}
                />
                <TextInput
                    label="Descrição Adicional"
                    source="additionalDescription"
                />
                <RadioButtonGroupInput
                    label="Tipo"
                    source="valueType"
                    helperText={false}
                    choices={[
                        { id: 1, name: 'Valor' },
                        { id: 2, name: 'Sim' },
                        { id: 3, name: 'Não' },
                        { id: 4, name: 'Descrição' },
                    ]}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.valueType === 1 && (
                            <TextInput label="Valor" source="value" {...rest} />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.valueType === 4 && (
                            <TextInput
                                label="Descrição"
                                source="valueDescription"
                                {...rest}
                            />
                        )
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.valueType === 1 && (
                            <TextInput
                                label="Moeda"
                                source="currency"
                                validate={[required()]}
                            />
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
}
