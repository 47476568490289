import React from 'react'
import { useEffect } from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    useShowController,
    Create,
    SimpleForm,
    TextInput,
    ShowButton,
    Show,
    TopToolbar,
    SelectInput,
    useCheckAuth,
    useRefresh,
    Loading,
} from 'react-admin'
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Paper,
    TableCell,
    TableBody,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    IconButton,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import BackButton from '../../components/BackButton'
import api from '../../Services/Api'
import Icon from '@material-ui/icons/LocationOn'
import DeleteIcon from '@material-ui/icons/Delete'
import { required, minLength, email } from 'react-admin'
export const HotelGroupIcon = Icon

const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            marginTop: 10,
            padding: 10,
        },
        '& h4': {
            marginTop: 0,
        },
    },
    actions: {
        justifyContent: 'start', //botoes de ação na esquerda da tela
        '& button': {
            margin: 5,
        },
    },
    statusInput: {
        color: 'red',
    },
})

export const HotelGroupList = (props) => {
    return (
        <List
            {...props}
            exporter={false}
            title="Grupos de Hotéis"
            bulkActionButtons={false}
            sort={{ field: 'name', order: 'ASC' }}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="name" label="Grupo" />
                <ShowButton label="" />
            </Datagrid>
        </List>
    )
}

const validateEmail = [required(), email()]
const validadePassword = [required(), minLength(6)]
export const HotelGroupCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput
                    label="E-Mail"
                    source="email"
                    validate={validateEmail}
                />
                <TextInput
                    label="Senha"
                    source="password"
                    validate={validadePassword}
                />
                <SelectInput
                    source="role"
                    choices={[
                        { id: 'admin', name: 'Administrador' },
                        { id: 'user', name: 'Usuário' },
                    ]}
                />
                <TextInput label="Código Gate" source="agentCode" />
            </SimpleForm>
        </Create>
    )
}

export const HotelGroupShow = ({ permissions, ...props }) => {
    const { record } = useShowController(props)
    const [addHotelModalOpen, setAddHotelModalOpen] = React.useState(false)

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] =
        React.useState(false)
    const [hotelToDelete, setHotelToDelete] = React.useState(null)
    const [hotelSelected, setHotelSelected] = React.useState(null)
    const [inputSelectedItem, setInputSelectedItem] = React.useState('')
    const [hotelList, setHotelList] = React.useState([])
    const classes = useStyles()

    useEffect(() => {
        ;(async () => {
            try {
                const result = await api.get(
                    `admin/hotels?_order=ASC&_sort=name&_start=0`
                )
                setHotelList(result.data)
            } catch (error) {
                console.log('Erro ao consultar hoteis', error)
            }
        })()
    }, [])

    const checkAuth = useCheckAuth()
    const handleCheckAuth = () => checkAuth()
    const refresh = useRefresh()

    const handleClick = () => {
        setAddHotelModalOpen(true)
    }

    const addHotelClick = async (hotelGroupId) => {
        try {
            await handleCheckAuth()
            await api.put(`admin/hotels/${hotelSelected.id}`, { hotelGroupId })
            setAddHotelModalOpen(false)
            refresh()
        } catch (error) {
            console.log('erro ao adicionar hotel ao grupo')
        }
    }

    const confirmDeleteClick = (hotelId) => {
        setHotelToDelete(hotelId)
        setConfirmDeleteModalOpen(true)
    }

    const deleteClick = async () => {
        try {
            await handleCheckAuth()
            await api.put(`admin/hotels/${hotelToDelete}`, {
                hotelGroupId: null,
            })
            setConfirmDeleteModalOpen(false)
            refresh()
        } catch (error) {
            console.log('erro ao remove hotel do grupo')
        }
    }

    if (record && hotelList.length > 0) {
        return (
            <>
                <Dialog
                    open={addHotelModalOpen}
                    onClose={() => setAddHotelModalOpen(false)}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Selecione o Hotel que deseja adicionar:
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Autocomplete
                            value={hotelSelected}
                            onChange={(event, newValue) => {
                                setHotelSelected(newValue)
                            }}
                            inputValue={inputSelectedItem}
                            onInputChange={(event, newInputValue) => {
                                setInputSelectedItem(newInputValue)
                            }}
                            getOptionLabel={(option) => option.name}
                            id="controllable-states-demo"
                            options={hotelList}
                            style={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Selecionar..."
                                    variant="outlined"
                                />
                            )}
                        />
                        <Button
                            onClick={() => addHotelClick(record.id)}
                            color="primary"
                        >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={confirmDeleteModalOpen}
                    onClose={() => setConfirmDeleteModalOpen(false)}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja remover o hotel do grupo ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => deleteClick()} color="primary">
                            Sim
                        </Button>
                        <Button
                            onClick={() => setConfirmDeleteModalOpen(false)}
                            color="primary"
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <TopToolbar className={classes.actions}>
                    <BackButton />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        
                        Adicionar Hotel
                    </Button>
                </TopToolbar>
                <Show {...props}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell size="medium">
                                        
                                        Grupo: <b> {record.name} </b>
                                    </TableCell>
                                    <TableCell size="medium"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                
                                {record.Hotel &&
                                    record.Hotel.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                
                                                {row.name}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        confirmDeleteClick(
                                                            row.id
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Show>
            </>
        )
    } else return <Loading />
}
