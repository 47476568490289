import React from 'react'
import { Link } from 'react-router-dom'
import { TopToolbar, useShowController, EditButton } from 'react-admin'
import { Grid, Paper, Button } from '@material-ui/core'
import BackButton from '../../components/BackButton'
import { makeStyles } from '@material-ui/core/styles'
import formatter from '../../formatter'
import { textAlign } from '@material-ui/system'

const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            marginTop: 10,
            padding: 10,
        },
        '& h4': {
            marginTop: 0,
        },
    },
    actions: {
        justifyContent: 'start', //botoes de ação na esquerda da tela
        '& button': {
            margin: 5,
        },
        paddingTop: '0px',
    },
})

export const HotelRoomShow = (props) => {
    const { record } = useShowController(props)
    const classes = useStyles()

    if (record) {
        return (
            <div className={classes.root}>
                <TopToolbar className={classes.actions}>
                    <Button
                        component={Link}
                        variant="contained"
                        to={`/hotels/${record.hotelId}/?tab=2`}
                        color="primary"
                    >
                        Voltar
                    </Button>
                </TopToolbar>
                <Paper>
                    Quarto: <b>{record.name}</b>
                </Paper>
                <Paper>
                    <h3 style={{ marginTop: '10px' }}>Pré Reservas:</h3>
                    <TopToolbar className={classes.actions}>
                        <Button
                            component={Link}
                            to={{
                                pathname: '/hotelRoomPrereservations/create/',
                                state: { record: { hotelRoomId: record.id } },
                            }}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            style={{ backgroundColor: 'Green' }}
                        >
                            Cadastrar Pré Reserva
                        </Button>
                    </TopToolbar>
                    {record.HotelRoomPreReservations &&
                        record.HotelRoomPreReservations.map(
                            (preReservation, index) => (
                                <Paper>
                                    <Grid
                                        container
                                        className={classes.list}
                                        style={{ maxWidth: '1440px' }}
                                    >
                                        <Grid container item>
                                            <Grid item xs={2}>
                                                <b>Início</b>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <b>Término</b>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <b>Total de diárias</b>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <b>Descrição</b>
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                        </Grid>
                                        <Grid
                                            key={index}
                                            container
                                            item
                                            alignItems="center"
                                            className={classes.listItem}
                                        >
                                            <Grid item xs={2}>
                                                {preReservation.dateStart}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {preReservation.dateEnd}
                                            </Grid>
                                            <Grid item xs={2}>
                                                {preReservation.days}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {preReservation.description}
                                            </Grid>
                                            <Grid item xs={2}>
                                                <EditButton
                                                    basePath="/hotelRoomPrereservations"
                                                    label=""
                                                    record={{
                                                        id: preReservation.id,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <h3>Pacotes de diárias</h3>
                                            <Button
                                                component={Link}
                                                to={{
                                                    pathname:
                                                        '/hotelRoomPreReservationPackages/create/',
                                                    state: {
                                                        record: {
                                                            hotelRoomId:
                                                                preReservation.hotelRoomId,
                                                            hotelRoomPreReservationId:
                                                                preReservation.id,
                                                        },
                                                    },
                                                }}
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                style={{ marginBottom: '10px' }}
                                            >
                                                Novo Pacote de Diárias
                                            </Button>
                                        </Grid>
                                        <Grid container item>
                                            <Grid item xs={1}>
                                                <b>Diárias</b>
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <b>Tarifa</b>
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <b>ISS</b>
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <b>Markup</b>
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <b>Markup %</b>
                                            </Grid>
                                            <Grid
                                                item
                                                xs
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <b>Total</b>
                                            </Grid>
                                            <Grid item xs></Grid>
                                        </Grid>
                                        {preReservation.HotelRoomPreReservationPackages &&
                                            preReservation.HotelRoomPreReservationPackages.map(
                                                (item, index) => (
                                                    <Grid
                                                        key={index}
                                                        container
                                                        item
                                                    >
                                                        <Grid item xs={1}>
                                                            {item.days}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {formatter.format(
                                                                item.fare
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {item.iss}%
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {formatter.format(
                                                                item.markup
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {item.markupPerc}%
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                            }}
                                                        >
                                                            {formatter.format(
                                                                item.total
                                                            )}
                                                        </Grid>
                                                        <Grid item xs>
                                                            <EditButton
                                                                basePath="/hotelRoomPrereservationPackages"
                                                                label=""
                                                                record={{
                                                                    id: item.id,
                                                                    hotelRoomId:
                                                                        preReservation.hotelRoomId,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )}
                                    </Grid>
                                </Paper>
                            )
                        )}
                </Paper>
            </div>
        )
    } else {
        return null
    }
}
