import React from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    EditButton,
    TextInput,
    SimpleForm,
    Edit,
    required,
} from 'react-admin'
import Icon from '@material-ui/icons/Settings'

export const SettingsIcon = Icon

export const SettingsList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="Configurações"
            bulkActionButtons={false}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="markup" label="Markup %" />
                <EditButton label="" />
            </Datagrid>
        </List>
    )
}

export const SettingsEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput
                    label="Markup Global"
                    source="markup"
                    validate={[required()]}
                />
            </SimpleForm>
        </Edit>
    )
}
