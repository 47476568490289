import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import customRoutes from './customRoutes'
import authProvider from './authProvider'
import { OrderEdit, OrderList, OrderShow } from './resources/orders/orders'
import { RoomEdit } from './resources/rooms/rooms'
import {
    UserList,
    UserShow,
    UserCreate,
    UserEdit,
    UserIcon,
} from './resources/users/users'
import { HotelList, HotelEdit, HotelIcon } from './resources/hotels/hotels'
import { TagList, TagEdit, TagCreate, TagIcon } from './resources/tags/tags'
import {
    CouponList,
    CouponEdit,
    CouponCreate,
    CouponIcon,
} from './resources/coupons/coupons'
import {
    HotelGroupList,
    HotelGroupIcon,
    HotelGroupShow,
} from './resources/hotelGroups/hotelGroups'
import { HotelRoomShow } from './resources/hotelRooms/hotelRooms'
import {
    HotelRoomPreReservationCreate,
    HotelRoomPreReservationEdit,
} from './resources/hotelRoomPreReservations/hotelRoomPreReservations'
import {
    HotelRoomPreReservationPackageCreate,
    HotelRoomPreReservationPackageEdit,
} from './resources/hotelRoomPreReservationPackages/hotelRoomPreReservationPackages'
import {
    destinyHighlightsList,
    DestinyHighlightsCreate,
    destinyHighlightsIcon,
} from './resources/destinyHighlights/destinyHighlights'
import closeSidebarSaga from './closeSidebarSaga'
import ptBrMessages from 'ra-language-pt-br'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import myDataProvider from './dataProvider'
import MyLoginPage from './pages/myLoginPage'

//assistance
import {
    AssistanceOrderEdit,
    AssistanceOrderList,
    AssistanceOrderShow,
} from './resources/assistance/assistanceOrders/assistanceOrders'
import {
    InsurersList,
    InsurersEdit,
    InsurersCreate,
    InsurersIcon,
} from './resources/assistance/insurers/insurers'
import {
    DestinationsList,
    DestinationsEdit,
    DestinationsCreate,
    DestinationsIcon,
} from './resources/assistance/destinations/destinations'
import {
    PaymentTypesList,
    PaymentTypesEdit,
    PaymentTypesCreate,
    PaymentTypesIcon,
} from './resources/assistance/paymentTypes/paymentTypes'
import {
    AssistanceCouponList,
    AssistanceCouponEdit,
    AssistanceCouponCreate,
    AssistanceCouponIcon,
} from './resources/assistance/assistanceCoupons/assistanceCoupons'
import {
    ProductsList,
    ProductsShow,
    ProductsEdit,
    ProductsCreate,
    ProductsIcon,
} from './resources/assistance/products/products'
import {
    CoveragesList,
    CoveragesEdit,
    CoveragesCreate,
    CoveragesIcon,
} from './resources/assistance/coverages/coverages'
import {
    ExchangeRatesList,
    ExchangeRatesEdit,
    ExchangeRatesCreate,
    ExchangeRatesIcon,
} from './resources/assistance/exchangeRates/exchangeRates'
import {
    ProductCoveragesEdit,
    ProductCoveragesCreate,
} from './resources/assistance/productCoverages/productCoverages'
import {
    ProductDestinationsEdit,
    ProductDestinationsCreate,
} from './resources/assistance/productDestinations/productDestinations'
import {
    AssistanceTagList,
    AssistanceTagEdit,
    AssistanceTagCreate,
    AssistanceTagIcon,
} from './resources/assistance/assistanceTags/assistanceTags'
import {
    ProductTagEdit,
    ProductTagCreate,
    ProductTagIcon,
} from './resources/assistance/productTags/productTags'
import {
    ProductFareAgeEdit,
    ProductFareAgeCreate,
    ProductFareAgeIcon,
} from './resources/assistance/productFareAges/productFareAges'
import {
    CoverageGroupList,
    CoverageGroupEdit,
    CoverageGroupCreate,
    CoverageGroupIcon,
} from './resources/assistance/coverageGroups/coverageGroups'
import {
    SettingsList,
    SettingsEdit,
    SettingsIcon,
} from './resources/assistance/settings/settings'

const messages = {
    'pt-br': ptBrMessages,
}
const i18nProvider = polyglotI18nProvider(
    (locale) => messages[locale],
    'pt-br',
    { allowMissing: true, onMissingKey: (key, _, __) => key }
)

//const i18nProvider = polyglotI18nProvider(() => ENi18n, "en", { allowMissing: true, onMissingKey: (key, _, __) => key });

const App = () => {
    return (
        <Admin
            customSagas={[closeSidebarSaga]}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            loginPage={MyLoginPage}
            customRoutes={customRoutes}
            dataProvider={myDataProvider}
        >
            {(permissions) => {
                return [
                    permissions.includes('admin') || permissions.includes('user')
                        ? [
                              <Resource
                                  name="orders"
                                  options={{ label: 'Pedidos (Hotéis)' }}
                                  list={OrderList}
                                  show={OrderShow}
                                  edit={OrderEdit}
                              />,
                          ]
                        : null,
                    permissions.includes('user')
                        ? [
                              <Resource
                                  name="hotels"
                                  options={{ label: 'Hotéis' }}
                                  list={HotelList}
                                  icon={HotelIcon}
                              />,
                          ]
                        : null,
                    permissions.includes('admin')
                        ? [
                              <Resource
                                  name="hotels"
                                  options={{ label: 'Hotéis' }}
                                  list={HotelList}
                                  edit={HotelEdit}
                                  icon={HotelIcon}
                              />,
                              <Resource
                                  name="tags"
                                  options={{ label: 'Tags' }}
                                  list={TagList}
                                  create={TagCreate}
                                  edit={TagEdit}
                                  icon={TagIcon}
                              />,
                              <Resource
                                  name="hotelRooms"
                                  options={{ label: 'Quartos' }}
                                  show={HotelRoomShow}
                              />,
                              <Resource
                                  name="hotelRoomPreReservations"
                                  options={{ label: 'Pré Reservas' }}
                                  create={HotelRoomPreReservationCreate}
                                  edit={HotelRoomPreReservationEdit}
                              />,
                              <Resource
                                  name="hotelRoomPreReservationPackages"
                                  options={{ label: 'Pacote Pré Reservas' }}
                                  create={HotelRoomPreReservationPackageCreate}
                                  edit={HotelRoomPreReservationPackageEdit}
                              />,
                              <Resource
                                  name="coupons"
                                  options={{ label: 'Cupons' }}
                                  list={CouponList}
                                  create={CouponCreate}
                                  edit={CouponEdit}
                                  icon={CouponIcon}
                              />,
                              <Resource
                                  name="hotelGroups"
                                  options={{ label: 'Grupo Hotéis' }}
                                  list={HotelGroupList}
                                  show={HotelGroupShow}
                                  icon={HotelGroupIcon}
                              />,
                              <Resource
                                  name="destinyHighlights"
                                  options={{ label: 'Destinos Destaque' }}
                                  list={destinyHighlightsList}
                                  create={DestinyHighlightsCreate}
                                  icon={destinyHighlightsIcon}
                              />,
                          ]
                        : null,

                    permissions.includes('assistanceadmin')
                        ? [
                              <Resource
                                  name="assistanceOrders"
                                  options={{ label: 'Pedidos (Seguros)' }}
                                  list={AssistanceOrderList}
                                  show={AssistanceOrderShow}
                                  edit={AssistanceOrderEdit}
                              />,
                              <Resource
                                  name="insurers"
                                  options={{ label: 'Seguradoras' }}
                                  list={InsurersList}
                                  create={InsurersCreate}
                                  edit={InsurersEdit}
                                  icon={InsurersIcon}
                              />,
                              <Resource
                                  name="destinations"
                                  options={{ label: 'Destinos' }}
                                  list={DestinationsList}
                                  create={DestinationsCreate}
                                  edit={DestinationsEdit}
                                  icon={DestinationsIcon}
                              />,
                              <Resource
                                  name="paymentTypes"
                                  options={{ label: 'Formas de pagamento' }}
                                  list={PaymentTypesList}
                                  create={PaymentTypesCreate}
                                  edit={PaymentTypesEdit}
                                  icon={PaymentTypesIcon}
                              />,
                              <Resource
                                  name="assistanceCoupons"
                                  options={{ label: 'Cupons' }}
                                  list={AssistanceCouponList}
                                  create={AssistanceCouponCreate}
                                  edit={AssistanceCouponEdit}
                                  icon={AssistanceCouponIcon}
                              />,
                              <Resource
                                  name="products"
                                  options={{ label: 'Produtos' }}
                                  list={ProductsList}
                                  create={ProductsCreate}
                                  edit={ProductsEdit}
                                  show={ProductsShow}
                                  icon={ProductsIcon}
                              />,
                              <Resource
                                  name="coverages"
                                  options={{ label: 'Coberturas' }}
                                  list={CoveragesList}
                                  create={CoveragesCreate}
                                  edit={CoveragesEdit}
                                  icon={CoveragesIcon}
                              />,
                              <Resource
                                  name="productCoverages"
                                  options={{ label: 'Cobertura' }}
                                  create={ProductCoveragesCreate}
                                  edit={ProductCoveragesEdit}
                              />,
                              <Resource
                                  name="coverageGroups"
                                  options={{ label: 'Grupo de Coberturas' }}
                                  list={CoverageGroupList}
                                  create={CoverageGroupCreate}
                                  edit={CoverageGroupEdit}
                                  icon={CoverageGroupIcon}
                              />,
                              <Resource
                                  name="productDestinations"
                                  options={{ label: 'Destino' }}
                                  create={ProductDestinationsCreate}
                              />,
                              <Resource
                                  name="exchangeRates"
                                  options={{
                                      label: 'Câmbio',
                                  }}
                                  list={ExchangeRatesList}
                                  create={ExchangeRatesCreate}
                                  edit={ExchangeRatesEdit}
                                  icon={ExchangeRatesIcon}
                              />,
                              <Resource
                                  name="assistanceTags"
                                  options={{ label: 'Tags' }}
                                  list={AssistanceTagList}
                                  create={AssistanceTagCreate}
                                  edit={AssistanceTagEdit}
                                  icon={AssistanceTagIcon}
                              />,
                              <Resource
                                  name="productTags"
                                  options={{ label: 'ProductTags' }}
                                  create={ProductTagCreate}
                                  edit={ProductTagEdit}
                                  icon={ProductTagIcon}
                              />,
                              <Resource
                                  name="productFareAges"
                                  options={{ label: 'ProductFareAges' }}
                                  create={ProductFareAgeCreate}
                                  edit={ProductFareAgeEdit}
                                  icon={ProductFareAgeIcon}
                              />,
                              <Resource
                                  name="settings"
                                  options={{ label: 'Configurações' }}
                                  list={SettingsList}
                                  edit={SettingsEdit}
                                  icon={SettingsIcon}
                              />,
                          ]
                        : null,

                    permissions.includes('assistanceadmin') ||
                    permissions.includes('admin')
                        ? [
                              <Resource
                                  name="users"
                                  options={{ label: 'Usuários' }}
                                  list={UserList}
                                  show={UserShow}
                                  create={UserCreate}
                                  edit={UserEdit}
                                  icon={UserIcon}
                              />,
                          ]
                        : null,

                    <Resource
                        name="rooms"
                        options={{ label: 'Quarto' }}
                        edit={RoomEdit}
                    />,
                ]
            }}
        </Admin>
    )
}

export default App
