import React from 'react'
import { useLocation } from 'react-router-dom'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    EditButton,
    TextInput,
    SimpleForm,
    Edit,
    Filter,
    SearchInput,
    NumberInput,
    DeleteButton,
    required,
    Create,
    TopToolbar
} from 'react-admin'
import Icon from '@material-ui/icons/Label'

export const ProductFareAgeIcon = Icon

const ProductFareAgeFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
)
export const ProductFareAgeList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="ProductFareAges"
            bulkActionButtons={false}
            sort={{ field: 'name', order: 'ASC' }}
            filters={<ProductFareAgeFilter />}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="name" label="Nome" />
                <EditButton label="" />
                <DeleteButton label="" />
            </Datagrid>
        </List>
    )
}

const currencyBRL = (value) => {
    value = value.replace('.', '')
    return value
}

export const ProductFareAgeEdit = (props) => {
    const redirect = (basePath, id, data) => `/products/${data.productId}/show/1`

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm redirect={redirect}>
                <TextInput
                    label="Idade Inicial"
                    source="startAge"
                    validate={[required()]}
                />
                <TextInput
                    label="Idade Final"
                    source="endAge"
                    validate={[required()]}
                />
                <TextInput
                    label="Valor"
                    source="value"
                    validate={[required()]}
                    parse={currencyBRL}
                />
                <TextInput
                    label="Moeda"
                    source="currency"
                    validate={[required()]}
                />
            </SimpleForm>
        </Edit>
    )
}

export const ProductFareAgeCreate = (props) => {
    const location = useLocation()

    const redirect = (basePath, id, data) => `/products/${data.productId}/show/1`

    const transform = (data) => ({
        ...data,
        productId: location.state.productId,
    })

    return (
        <Create {...props} mutationMode="pessimistic" transform={transform}>
            <SimpleForm redirect={redirect}>
                <TextInput
                    label="Idade Inicial"
                    source="startAge"
                    validate={[required()]}
                />
                <TextInput
                    label="Idade Final"
                    source="endAge"
                    validate={[required()]}
                />
                <TextInput
                    label="Valor"
                    source="value"
                    validate={[required()]}
                    parse={currencyBRL}
                />
                <TextInput
                    label="Moeda"
                    source="currency"
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    )
}
