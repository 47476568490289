import * as React from "react";
import { Route } from 'react-router-dom';
import auth from './pages/auth'
import forgotPassword from './pages/forgotPassword'
import ResetPassword from "./pages/resetPassword"

const routes = [
    <Route exact path="/auth/:token" component={auth} noLayout />,
    <Route exact path="/forgotpassword" component={forgotPassword} noLayout />,
    <Route exact path="/resetpassword/:token" component={ResetPassword} noLayout />
];

export default routes
