import React from 'react'
import { useLocation } from 'react-router-dom'
import { TextInput, Edit, SimpleForm, useEditController, TopToolbar } from 'react-admin'
import BackButton from '../../components/BackButton'

export const RoomEdit = (props) => {
    const { record } = useEditController(props)
    const location = useLocation()

    const redirect = (basePath, id, data) => `/orders/${data.orderId}/show`
    if (record && location.state && location.state.roomNumber) {
        record.roomNumber = location.state.roomNumber
        return (
            <>
                <TopToolbar style={{justifyContent: "start"}}>
                    <BackButton />
                </TopToolbar>
                <Edit
                    title="Alterar hóspede"
                    {...props}
                    mutationMode="pessimistic"
                >
                    <SimpleForm redirect={redirect}>
                        <TextInput label="Hóspede" source="ownerName" />
                    </SimpleForm>
                </Edit>
            </>
        )
    } else return null
}
