import React from 'react'
import {
    List, Datagrid, TextField as TextFieldRa, EditButton, TextInput, SimpleForm, Edit, Filter, SearchInput, BooleanInput, BooleanField, 
    DeleteButton, required, Create
} from 'react-admin'
import Icon from '@material-ui/icons/Label'

export const AssistanceTagIcon = Icon

const AssistanceTagFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
)
export const AssistanceTagList = ({ permissions, ...props }) => {
    return (
        <List {...props} exporter={false} title="AssistanceTags" bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} filters={<AssistanceTagFilter />} perPage={25}>
            <Datagrid>
                <TextFieldRa source="name" label="Nome" />
                <EditButton label="" />
                <DeleteButton label="" />
            </Datagrid>
        </List>
    )
}


export const AssistanceTagEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput disabled label="Código" source="id" />
                <TextInput label="Nome" source="name" validate={validateName} />
            </SimpleForm>
        </Edit>
    )
}

const validateName= [required()]
export const AssistanceTagCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm redirect="list">
                <TextInput label="Nome" source="name" validate={validateName} />
            </SimpleForm>
        </Create>
    )
}
