import React from 'react'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    EditButton,
    TextInput,
    SimpleForm,
    ImageInput,
    ImageField,
    BooleanInput,
    Edit,
    Filter,
    SearchInput,
    SelectInput,
    BooleanField,
    useEditController,
    TopToolbar,
    Toolbar,
    SaveButton,
    useCheckAuth,
    useRefresh,
    useNotify,
    SelectArrayInput,
    ResourceContextProvider,
    TextField,
    useResourceContext,
    ListBase,
    useRedirect,
} from 'react-admin'
import {
    Grid,
    Chip,
    Box,
    Paper,
    Tabs,
    Tab,
    Typography,
    AppBar,
    Card,
    CardActionArea,
    CardActions,
    CardMedia,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
} from '@material-ui/core'
import Icon from '@material-ui/icons/Business'
import BackButton from '../../components/BackButton'
import { hotelCategories } from '../../consts'
import { makeStyles } from '@material-ui/core/styles'
import api from '../../Services/Api'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import EditIcon from '@material-ui/icons/Edit'
import RemoveIcon from '@material-ui/icons/Delete'
import queryString from 'query-string'
import IconButton from '@material-ui/core/IconButton'
export const HotelIcon = Icon

const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            marginTop: 10,
            padding: 10,
        },
        '& h4': {
            marginTop: 0,
        },
    },
    formControl: {
        minWidth: 250,
    },
    actions: {
        justifyContent: 'start', //botoes de ação na esquerda da tela
        '& button': {
            margin: 5,
        },
        paddingTop: '0px',
    },
    card: {
        maxWidth: 345,
    },
    cardActions: {
        cursor: 'pointer',
        padding: '0px',
    },
    image: {
        height: 140,
    },
    default: {
        backgroundColor: '#3f51b5',
        color: 'white',
    },
    active: {
        backgroundColor: 'green',
        color: 'white',
    },
    inactive: {
        backgroundColor: 'red',
        color: 'white',
    },
    own: {
        backgroundColor: '#00aeef',
        color: 'white',
    },
    button: {
        marginTop: '0px!important',
        marginBottom: '0px!important',
    },
    list: {
        padding: '20px',
    },
    listItem: {
        margin: '0px',
    },
})

const HotelFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
    </Filter>
)

export const HotelList = ({ permissions, ...props }) => {
    return (
        <List
            {...props}
            exporter={false}
            title="Lista de Hotéis"
            bulkActionButtons={false}
            sort={{ field: 'name', order: 'ASC' }}
            filters={<HotelFilter />}
            perPage={25}
        >
            <Datagrid>
                <TextFieldRa source="code" label="Código" />
                <TextFieldRa source="name" label="Nome" />
                <TextFieldRa source="city" label="Cidade" />
                <TextFieldRa source="state" label="Estado" />
                <TextFieldRa
                    source="categoryText"
                    label="Categoria"
                    sortBy="categoryId"
                />
                <BooleanField source="firstPage" label="Primeira Página" />
                <BooleanField source="highlight" label="Destaque" />
                {permissions.includes('admin') && (
                    <TextFieldRa source="markup" label="% Markup" />
                )}
                <TextFieldRa source="createdAt" label="Data cadastro" />
                {!permissions.includes('user') && <EditButton label="" />}
            </Datagrid>
        </List>
    )
}

const UserEditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
)

export const HotelEdit = ({ permissions, ...props }) => {
    const { record } = useEditController(props)
    const classes = useStyles()
    const checkAuth = useCheckAuth()
    const handleCheckAuth = () => checkAuth()
    const refresh = useRefresh()
    const notify = useNotify()
    const location = useLocation()

    useEffect(() => {
        ;(async () => {
            try {
                if (
                    location.pathname.includes('?tab=2') ||
                    location.search.includes('?tab=2')
                )
                    setValue(2)
            } catch (error) {
                console.log('Erro ao buscar tab', error)
            }
        })()
    }, [location.pathname, location.search])

    const [value, setValue] = React.useState(0)
    const [openImage, setOpenImage] = React.useState(false)
    const [activeImage, setActiveImage] = React.useState(null)
    const [activeVideo, setActiveVideo] = React.useState(null)
    const [newTag, setNewTag] = React.useState('')
    const [checkedList, setCheckedList] = React.useState([])
    const [openSendImagesDialog, setOpenSendImagesDialog] =
        React.useState(false)
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false)
    const [openNewTagDialog, setOpenNewTagDialog] = React.useState(false)
    const [openDeleteTagDialog, setOpenDeleteTagDialog] = React.useState(false)
    const [hotelTagToDelete, setHotelTagToDelete] = React.useState(null)
    const [tagOptions, setTagOptions] = React.useState([])

    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography> {children} </Typography>
                    </Box>
                )}
            </div>
        )
    }

    const handleImageClick = (imageUrl, videoUrl) => {
        if (imageUrl) {
            setActiveImage(imageUrl)
            setActiveVideo(null)
        } else {
            setActiveImage(null)
            const videoCode = videoUrl
                .replace('https://www.youtube.com/watch?v=', '')
                .replace('https://youtu.be/', '')
            setActiveVideo(videoCode)
        }
        setOpenImage(true)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeNewTag = (value) => {
        setNewTag(value)
    }

    const handleChecked = (image) => {
        if (!checkedList.find((a) => a.id === image.id))
            setCheckedList((oldValue) => [...oldValue, image])
        else setCheckedList(checkedList.filter((a) => a.id !== image.id))
    }

    const handleSetDefault = async () => {
        try {
            await handleCheckAuth()
            await api.post('/hotel/image/setDefault', {
                imageId: checkedList.map((a) => a.id)[0],
                hotelCode: record.code,
            })
            setCheckedList([])
            refresh()
        } catch (error) {
            console.log('erro ao definir imagem como capa: ', error)
        }
    }

    const handleLoadRooms = async (hotelId) => {
        try {
            await handleCheckAuth()
            await api.post(`/admin/hotel/loadRooms/${hotelId}`)
            refresh()
        } catch (error) {
            console.log('erro ao carregar quartos: ', error)
        }
    }

    const handleInactivate = async () => {
        try {
            await handleCheckAuth()
            await api.post('/hotel/image/inactivate', {
                images: checkedList.map((a) => a.id),
            })
            setCheckedList([])
            refresh()
        } catch (error) {
            console.log('erro ao inativar imagens: ', error)
        }
    }

    const handleActivate = async () => {
        try {
            await handleCheckAuth()
            await api.post('/hotel/image/activate', {
                images: checkedList.map((a) => a.id),
            })
            setCheckedList([])
            refresh()
        } catch (error) {
            console.log('erro ao ativar imagens: ', error)
        }
    }

    const handleDeleteImage = async () => {
        try {
            await handleCheckAuth()
            await api.delete(`/hotel/image/${checkedList[0].id}`)
            setCheckedList([])
            refresh()
        } catch (error) {
            console.log('erro ao excluir imagem: ', error)
        }
    }

    const handleNewTag = async (hotelTags) => {
        try {
            const result = await api.get(`admin/tags`)
            hotelTags = hotelTags.map((a) => a.tagId)
            const tags = result.data.filter((a) => !hotelTags.includes(a.id))
            setTagOptions(tags)
            setNewTag('')
            setOpenNewTagDialog(true)
        } catch (error) {
            console.log('Erro ao consultar tags', error)
        }
    }

    const newHotelTag = async (hotelId) => {
        try {
            await handleCheckAuth()
            await api.post(`admin/hotelTags`, { hotelId, tagId: newTag })
            setOpenNewTagDialog(false)
            refresh()
        } catch (error) {
            console.log('erro ao remover tag do hotel')
        }
    }

    const handleDeleteTag = (hotelTagId) => {
        setHotelTagToDelete(hotelTagId)
        setOpenDeleteTagDialog(true)
    }

    const deleteTag = async () => {
        try {
            await handleCheckAuth()
            await api.delete(`admin/hotelTags/${hotelTagToDelete}`)
            refresh()
        } catch (error) {
            console.log('erro ao remover tag do hotel')
        }
    }

    const handleShowInResults = async (hotelTag, showInResults) => {
        try {
            await handleCheckAuth()
            await api.put(`admin/hotelTags/${hotelTag.id}`, { showInResults })
            refresh()
        } catch (error) {
            console.log('erro ao atualizar tag do hotel')
        }
    }

    const TextInputCustom = (props) => (
        <TextInput {...props} style={{ width: 400 }} />
    )

    const onSuccess = () => {
        notify(`Imagens enviadas com sucesso!`)
        setOpenSendImagesDialog(false)
        refresh()
    }

    if (record)
        return (
            <div className={classes.root}>
                <Dialog //mostrar imagem ou video em tamanho real
                    open={openImage}
                    onClose={() => setOpenImage(false)}
                    maxWidth="lg"
                >
                    <DialogContent>
                        {activeImage && <img src={activeImage} alt="imagem" />}
                        {activeVideo && (
                            <iframe
                                width="800"
                                height="600"
                                src={`https://www.youtube.com/embed/${activeVideo}`}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        )}
                    </DialogContent>
                </Dialog>
                <Dialog //enviar imagens
                    open={openSendImagesDialog}
                    onClose={() => setOpenSendImagesDialog(false)}
                    maxWidth="lg"
                >
                    <DialogContent>
                        <Edit
                            onSuccess={onSuccess}
                            mutationMode="pessimistic"
                            {...props}
                        >
                            <SimpleForm toolbar={<UserEditToolbar />}>
                                <ImageInput
                                    multiple
                                    source="image"
                                    label="Imagem"
                                    accept="image/*"
                                >
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                                <TextInputCustom
                                    label="Url do vídeo"
                                    source="videoUrl"
                                />
                            </SimpleForm>
                        </Edit>
                    </DialogContent>
                </Dialog>
                <Dialog //confirmar exclusao
                    open={openConfirmDelete}
                    onClose={() => setOpenConfirmDelete(false)}
                    maxWidth="lg"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja excluir essa imagem ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                handleDeleteImage()
                                setOpenConfirmDelete(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setOpenConfirmDelete(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog //confirmar exclusao tag
                    open={openDeleteTagDialog}
                    onClose={() => setOpenDeleteTagDialog(false)}
                    maxWidth="lg"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja excluir essa tag do hotel?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                deleteTag()
                                setOpenDeleteTagDialog(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setOpenDeleteTagDialog(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog //Adicionar tag
                    open={openNewTagDialog}
                    onClose={() => setOpenNewTagDialog(false)}
                    maxWidth="lg"
                >
                    <DialogContent>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Nova Tag
                            </InputLabel>
                            <Select
                                labelId="newTag-label"
                                id="newTag"
                                value={newTag}
                                onChange={(e) =>
                                    handleChangeNewTag(e.target.value)
                                }
                                label="Nova Tag"
                            >
                                {tagOptions &&
                                    tagOptions.map((tag) => (
                                        <MenuItem value={tag.id}>
                                            {tag.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => newHotelTag(record.id)}
                            color="primary"
                        >
                            Confirmar
                        </Button>
                        <Button
                            onClick={() => setOpenNewTagDialog(false)}
                            color="primary"
                            autoFocus
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
                <TopToolbar className={classes.actions}>
                    <BackButton />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setOpenSendImagesDialog(true)}
                    >
                        Inserir Imagem ou Vídeo
                    </Button>
                </TopToolbar>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Informações" id="info" />
                        <Tab label="Imagens" id="images" />
                        <Tab label="Quartos" id="rooms" />
                        <Tab label="Tags" id="tags" />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Edit {...props}>
                        <SimpleForm toolbar={<UserEditToolbar />}>
                            <TextInputCustom
                                disabled
                                label="Código"
                                source="code"
                            />
                            <TextInputCustom label="Nome" source="name" />
                            <TextInputCustom
                                disabled
                                label="Nome (Provedor)"
                                source="providerName"
                            />
                            <TextInputCustom
                                disabled
                                label="Cidade"
                                source="city"
                            />
                            <TextInputCustom
                                disabled
                                label="Estado"
                                source="state"
                            />
                            <TextInputCustom
                                disabled
                                label="Endereço"
                                source="addressLine"
                            />
                            <SelectInput
                                label="Categoria"
                                source="categoryId"
                                choices={hotelCategories}
                            />
                            <BooleanInput
                                label="Exibir na primeira página"
                                source="firstPage"
                            />
                            <BooleanInput
                                label="Destaque da categoria"
                                source="highlight"
                            />
                            {permissions.includes('admin') && (
                                <TextInputCustom
                                    label="% Markup"
                                    source="markup"
                                />
                            )}
                            <TextInputCustom
                                multiline
                                label="Descrição"
                                source="description"
                            />
                            <TextInputCustom
                                multiline
                                disabled
                                label="Descrição (Provedor)"
                                source="providerDescription"
                            />
                        </SimpleForm>
                    </Edit>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Paper>
                        <TopToolbar className={classes.actions}>
                            {checkedList.length > 0 &&
                                checkedList.find((a) => a.active === false) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={handleActivate}
                                        style={{ backgroundColor: 'Green' }}
                                    >
                                        Ativar
                                    </Button>
                                )}
                            {checkedList.find((a) => a.active === true) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleInactivate}
                                    style={{ backgroundColor: 'Red' }}
                                >
                                    Inativar
                                </Button>
                            )}
                            {checkedList.filter((a) => a.default === false)
                                .length === 1 &&
                                checkedList.filter((a) => a.default === true)
                                    .length === 0 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={handleSetDefault}
                                    >
                                        Tornar Capa
                                    </Button>
                                )}
                            {checkedList.length === 1 &&
                                checkedList.filter((a) => a.ownImage === true)
                                    .length === 1 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() =>
                                            setOpenConfirmDelete(true)
                                        }
                                    >
                                        Excluir
                                    </Button>
                                )}
                        </TopToolbar>
                        <Grid container spacing={1}>
                            {record.images &&
                                record.images.map((image, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        xs={12}
                                        md={4}
                                        lg={3}
                                    >
                                        <Card className={classes.card}>
                                            <CardActionArea
                                                onClick={() =>
                                                    handleImageClick(
                                                        image.url,
                                                        image.videoUrl
                                                    )
                                                }
                                            >
                                                <CardMedia
                                                    className={classes.image}
                                                    image={
                                                        image.url
                                                            ? image.url
                                                            : `https://img.youtube.com/vi/KOcyPBiFrNg/0.jpg`
                                                    }
                                                />
                                            </CardActionArea>
                                            <CardActions
                                                onClick={() =>
                                                    handleChecked(image)
                                                }
                                                className={classes.cardActions}
                                            >
                                                <Checkbox
                                                    checked={
                                                        checkedList.find(
                                                            (a) =>
                                                                a.id ===
                                                                image.id
                                                        ) != null
                                                    }
                                                    inputProps={{
                                                        'aria-label':
                                                            'primary checkbox',
                                                    }}
                                                />
                                                {image.default && (
                                                    <Chip
                                                        size="small"
                                                        className={
                                                            classes.default
                                                        }
                                                        label="Capa"
                                                    />
                                                )}
                                                {image.active && (
                                                    <Chip
                                                        size="small"
                                                        className={
                                                            classes.active
                                                        }
                                                        label="Ativa"
                                                    />
                                                )}
                                                {image.active === false && (
                                                    <Chip
                                                        size="small"
                                                        className={
                                                            classes.inactive
                                                        }
                                                        label="Inativa"
                                                    />
                                                )}
                                                {image.ownImage && (
                                                    <Chip
                                                        size="small"
                                                        className={classes.own}
                                                        label="Própria"
                                                    />
                                                )}
                                                {image.videoUrl && (
                                                    <PlayCircleFilledIcon color="primary" />
                                                )}
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                        </Grid>
                    </Paper>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Paper>
                        <TopToolbar className={classes.actions}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => handleLoadRooms(record.id)}
                                style={{ backgroundColor: 'Green' }}
                            >
                                Carregar Quartos
                            </Button>
                        </TopToolbar>
                        <Grid container className={classes.list}>
                            {record.HotelRooms && (
                                <Grid container item>
                                    <Grid item xs={3}>
                                        <b>Código</b>
                                    </Grid>
                                    <Grid item xs>
                                        <b>Descrição</b>
                                    </Grid>
                                    <Grid item xs></Grid>
                                </Grid>
                            )}
                            {record.HotelRooms &&
                                record.HotelRooms.map((room, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        item
                                        alignItems="center"
                                        className={classes.listItem}
                                    >
                                        <Grid item xs={3}>
                                            {room.code}
                                        </Grid>
                                        <Grid item xs>
                                            {room.name}
                                        </Grid>
                                        <Grid item xs>
                                            <Button
                                                component={Link}
                                                to={`/hotelRooms/${room.id}/show`}
                                                size="small"
                                                color="primary"
                                            >
                                                <EditIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    </Paper>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Paper>
                        <TopToolbar className={classes.actions}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => handleNewTag(record.tags)}
                                style={{ backgroundColor: 'Green' }}
                            >
                                Adicionar Tag
                            </Button>
                        </TopToolbar>
                        <Grid container className={classes.list}>
                            {record.tags && (
                                <Grid container item>
                                    <Grid item xs={3}>
                                        <b>Tag</b>
                                    </Grid>
                                    <Grid item xs>
                                        <b>
                                            Exibir na pesquisa (Máximo 3 opções)
                                        </b>
                                    </Grid>
                                    <Grid item xs></Grid>
                                </Grid>
                            )}
                            {record.tags &&
                                record.tags.map((hotelTag, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        item
                                        alignItems="center"
                                        className={classes.listItem}
                                    >
                                        <Grid item xs={3}>
                                            {hotelTag['Tag.name']}
                                        </Grid>
                                        <Grid item xs>
                                            <Checkbox
                                                disabled={
                                                    !hotelTag.showInResults &&
                                                    record.tags.filter(
                                                        (a) => a.showInResults
                                                    ).length >= 3
                                                }
                                                checked={hotelTag.showInResults}
                                                onChange={() =>
                                                    handleShowInResults(
                                                        hotelTag,
                                                        !hotelTag.showInResults
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Button
                                                onClick={() =>
                                                    handleDeleteTag(hotelTag.id)
                                                }
                                                size="small"
                                                color="primary"
                                            >
                                                <RemoveIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ))}
                        </Grid>
                    </Paper>
                </TabPanel>
            </div>
        )
    else return null
}
